<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "dashboardLayout",
  components: {},
  mounted() {
    if (
      this.$vuetify.lang.current !==
        this.$store.getters["app/getSelectedLocale"] &&
      this.$store.getters["app/getSelectedLocale"]
    ) {
      this.$vuetify.lang.current = this.$store.getters["app/getSelectedLocale"];
    }
  },
};
</script>

<style scoped></style>
